import "./styles.css";

const urlParams = new URLSearchParams(window.location.search);
var page = urlParams.get("page");
const id = urlParams.get("id");

if (!page) {
  page = "ProductPage";
}

var url = `fitnu://&page=${page}&id=${id}`;
window.location.replace(url);

document.getElementById("app").innerHTML = `
<h1>Fit.nu app viderestilling...</h1>
<div>
Vi sender dig videre til Fit.nu-appen. Hvis det ikke sker automatisk, kan du trykke på linket nedenfor.
  <br /><br />
  <a href="${url}" target="_blank" rel="noopener noreferrer">Fortsæt til Fit.nu-appen her</a>.
</div>
`;
